const myTheme = {
  borderRadius: '5px',
  colors: {
    black: '#000000',
    white: '#ffffff',
    blue: '#3973B3',
    gray: '#6F6F6F',
    blueHover: 'rgba(57,115,179, 0.2)',
    red: '#ff0000',
    dark: '#222222',
    bg: '#FBFBFB',
    inputBg: '#ffffff',
    inputPlaceholder: '#949494',
    border: '#eeeeee',
    borderLight: '#F1F1F1',
    bgBlack: '#00000020',
  },
  gaps: {
    xs: '0.5rem',
    s: '1rem',
    default: '2rem',
    m: '3rem',
    l: '4rem',
    xl: '5rem',
    xxl: '6rem',
  },
}

export { myTheme }
