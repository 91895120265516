import * as Yup from 'yup'
import { NIP, PESEL } from 'id-pl'

const NIP_EXCLUDE = [
  '0000000000',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
]

const validateNip = (nip) => {
  if (NIP_EXCLUDE.includes(nip)) return false
  return NIP.isValid(nip)
}

const validatePesel = (pesel) => PESEL.isValid(pesel)

Yup.setLocale({
  mixed: {
    required: 'To pole jest wymagane',
  },
})

export const ankietaKsiegowoscKadrySchema = Yup.object().shape({
  // Section 1
  isNewCompany: Yup.string().required(),
  businessType: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  entityName: Yup.string().required(), // Tu się zmienia tresć pytania, ale nie walidacja
  nip: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () =>
      Yup.string()
        .required()
        .test('format', 'Niepoprawny format NIP', (value) => validateNip(String(value))),
    otherwise: () => Yup.string(),
  }),
  pesel: Yup.string().when('businessType', {
    is: (businessType) =>
      ['jednoosobowa-dzialalnosc-gospodarcza', 'spolka-cywilna', 'spolka-jawna'].includes(businessType),
    then: () =>
      Yup.string()
        .required()
        .test('format', 'Niepoprawny PESEL', (value) => validatePesel(String(value))),
    otherwise: () => Yup.string(),
  }),
  phone: Yup.string().required(),
  www: Yup.string().url('Nieprawidłowy adres'),
  email: Yup.string().email('Błędny format adresu email').required(),
  // Section 2
  zipCode: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  street: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  city: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  buildingNumber: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  // Section 4
  businessModel: Yup.string().required(), // Tu się zmienia tresć pytania, ale nie walidacja
  whoDoesAccounting: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  whoDoesAccountingOther: Yup.string(),
  areYouSatisfied: Yup.string(),
  areYouSatisfiedOther: Yup.string(),
  whatIsMissingFromService: Yup.string(),
  modernServiceYouCareAbout: Yup.array(),
  whyYouWantToChangeService: Yup.string(),
  formOfSettlement: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  tax: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  foreignIncome: Yup.boolean().required(),
  isActiveVatPayer: Yup.boolean().required(),
  vat: Yup.string().when(['isActiveVatPayer', 'isNewCompany'], {
    is: (isActiveVatPayer, isNewCompany) => isActiveVatPayer && ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  vatEU: Yup.boolean().required(),

  numberOfRevenueInvoicesMonthly: Yup.number().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  numberOfCostInvoicesMonthly: Yup.number().when('isNewCompany', {
    is: (isNewCompany) => ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  numberOfForeingRevenueInvoicesMonthly: Yup.number().when('isNewCompany', {
    is: (isNewCompany) => ['nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  numberOfForeingCostInvoicesMonthly: Yup.number().when('isNewCompany', {
    is: (isNewCompany) => ['nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  doYouEmployWorkers: Yup.boolean().required(),
  numberOfEmployeesContract: Yup.number().when(['isNewCompany', 'doYouEmployWorkers'], {
    is: (isNewCompany, doYouEmployWorkers) => doYouEmployWorkers && ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  numberOfEmployeesMandate: Yup.number().when(['isNewCompany', 'doYouEmployWorkers'], {
    is: (isNewCompany, doYouEmployWorkers) => doYouEmployWorkers && ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  numberOfNonresidentEmployees: Yup.number().when(['isNewCompany', 'doYouEmployWorkers'], {
    is: (isNewCompany, doYouEmployWorkers) => doYouEmployWorkers && ['tak', 'nie'].includes(isNewCompany),
    then: () => Yup.number().min(0, 'Niepoprawna wartość').required(),
    otherwise: () => Yup.number().min(0, 'Niepoprawna wartość'),
  }),
  usedProgram: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  programActions: Yup.array().when('isNewCompany', {
    is: (isNewCompany) => ['nie'].includes(isNewCompany),
    then: () => Yup.array().required().min(1, 'To pole jest wymagane'),
    otherwise: () => Yup.array(),
  }),
  programActionsOther: Yup.string(),
  whatSoftwareAccountantWorkOn: Yup.string().when('isNewCompany', {
    is: (isNewCompany) => ['nie'].includes(isNewCompany),
    then: () => Yup.string().required(),
    otherwise: () => Yup.string(),
  }),
  // Section 5
  costAccountant: Yup.string(),
  costHumanResources: Yup.string(),
  // Section 6
  howYouLearnAboutUs: Yup.array(),
  howYouLearnAboutUsOther: Yup.string(),
  howYouLearnAboutUsName: Yup.string(),
  howYouLearnAboutUsPhone: Yup.string(),
  othersInformations: Yup.string(),
  regulations: Yup.boolean().oneOf([true], 'Zgoda jest wymagana').required(),
})
