import React from 'react'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import LogoDark from 'assets/images/logo-dark.png'
import { centerBlock } from 'theme/mixins'

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <FigureLogo>
          <img src={LogoDark} alt='Coinbaq' />
        </FigureLogo>
      </Container>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.header`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const FigureLogo = styled.figure`
  ${centerBlock}
  max-width: 21.5rem;
  width: 100%;
`
