import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import * as typo from 'theme/typography'
import { FormControl, Error } from 'components/Forms/elements'
import { placeholder } from 'theme/mixins'

const TextInput = ({ label = '', hint = '', field, form: { touched, errors }, ...props }) => {
  return (
    <FormControl>
      <InputWrapper>
        {label && <Label htmlFor={field.name}> {label} </Label>}
        <Input $valid={Boolean(!errors[field.name] || props.disabled)} id={field.name} {...field} {...props} />
        {hint && <Hint>{hint}</Hint>}
      </InputWrapper>
      {touched[field.name] && errors[field.name] && <CustomError>{errors[field.name]}</CustomError>}
    </FormControl>
  )
}

TextInput.propTypes = {
  label: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default TextInput

const CustomError = styled(Error)`
  margin-top: 4px;
  margin-left: 4px;
`

const InputWrapper = styled.div`
  position: relative;
`
const Hint = styled.p`
  ${typo.ParagraphXS}
  color: ${(props) => props.theme.colors.inputPlaceholder};
`

const Label = styled.label`
  ${typo.InputLabel}
  display: inline-block;
  margin-bottom: 0.6rem;
`

const Input = styled.input`
  ${typo.Input}
  height: 4rem;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  display: block;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.border};
  border-radius: ${(props) => props.theme.borderRadius};
  transition: border-color 0.3s;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:active,
  &:focus {
    border-color: ${(props) => props.theme.colors.blue};
  }

  ${placeholder(css`
    color: ${(props) => props.theme.colors.inputPlaceholder};
  `)}

  ${(props) =>
    props.valid &&
    props.disabled &&
    css`
      background-position: right 0.6rem top 50%;
      background-size: 1.6rem;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg version='1.2' xmlns='http://www.w3.org/2000/svg' width='17' height='17'%3E%3Cdefs%3E%3Cimage width='17' height='17' id='a' href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAAXNSR0IB2cksfwAAADxQTFRFIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UIC1UDFMsGgAAABR0Uk5TL9T/hl94ACvH4STkDBXTpQNkqEsqXqBmAAAAS0lEQVR4nGNgYGRCBowMaAIgISZ0QKoIMwuaCCsbO1yEgxMswMUNF+Hh5YMLQET4BXgFYQJQc/gF4AIwk4WEYQIkuxAqgul3jPABAOUnA0TBMxabAAAAAElFTkSuQmCC'/%3E%3C/defs%3E%3Cuse href='%23a' opacity='.2'/%3E%3C/svg%3E");
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: ${(props) => props.theme.colors.inputPlaceholder};
      background-color: ${(props) => props.theme.colors.inputBg};
    `}
`
