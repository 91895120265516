export const endpoints = {
  ankietaKsiekowośćKardy: 'https://coinbaq.online/pdf.php',
}

export const businessTypeOptions = [
  { value: 'jednoosobowa-dzialalnosc-gospodarcza', display: 'Jednoosobowa działalność gospodarcza' },
  { value: 'spolka-cywilna', display: 'Spółka cywilna' },
  { value: 'spolka-jawna', display: 'Spółka jawna' },
  { value: 'spolka-z-ograniczona-odpowiedzialnoscia', display: 'Spółka z ograniczoną odpowiedzialnością' },
  { value: 'fundacja-rodzinna', display: 'Fundacja rodzinna' },
  { value: 'inne', display: 'Inne' },
]

export const isNewCompanyOptions = [
  { value: 'tak', display: 'Tak' },
  { value: 'nie', display: 'Nie' },
  { value: 'planuje', display: 'Planuję założyć firmę' },
]

export const whoDoesAccountingOptions = [
  { value: 'ksiegowa-zatrudniona', display: 'Księgowa zatrudniona' },
  { value: 'biuro-rachunkowe', display: 'Biuro rachunkowe' },
  { value: 'klient-sam-prowadzi', display: 'Samodzielnie' },
  { value: 'inne', display: 'Inne' },
]

export const areYouSatisfiedOptions = [
  { value: 'tak', display: 'Tak' },
  { value: 'nie', display: 'Nie' },
  { value: 'inne', display: 'Inny powód' },
]

export const modernServiceYouCareAboutOptions = [
  { value: 'automatyczne-wystawianie-faktur', display: 'Automatyczne wystawianie faktur' },
  { value: 'automatyczne-odczytywanie-faktur-i-dokumentow', display: 'Automatyczne odczytywanie faktur i dokumentów' },
  { value: 'dokumentacja-elektroniczna-dla-dzialu-kadr', display: 'Dokumentacja elektroniczna dla działu kadr' },
  { value: 'integracja-z-ksef', display: 'Integracja z KSeF' },
  { value: 'inne', display: 'Inne' },
]

export const formOfSettlementOptions = [
  { value: 'karta-podatkowa', display: 'Karta podatkowa' },
  { value: 'ryczalt', display: 'Ryczałt' },
  {
    value: 'ksiega-przychodow-i-rozchodow-podatek-progresywny',
    display: 'Księga Przychodów i Rozchodów - podatek progresywny',
  },
  {
    value: 'ksiega-przychodow-i-rozchodow-podatek-liniowy',
    display: 'Księga Przychodów i Rozchodów - podatek liniowy',
  },
  { value: 'ksiegi-handlowe-pelna-ksiegowosc', display: 'Księgi Handlowe (Pełna księgowość)' },
]

export const taxOptions = [
  { value: 'podatek-kwartalny', display: 'Podatek kwartalny' },
  { value: 'podatek-miesieczny', display: 'Podatek miesięczny' },
]

export const vatOptions = [
  { value: 'podatek-vat-kwartalny', display: 'Podatek VAT kwartalny' },
  { value: 'podatek-vat-miesieczny', display: 'Podatek VAT miesięczny' },
]

export const programActions = [
  { value: 'fakturowanie', display: 'Fakturowanie' },
  { value: 'magazynowanie', display: 'Magazynowanie' },
  { value: 'analizy', display: 'Analizy' },
  { value: 'inne', display: 'Inne' },
]

export const howYouLearnAboutUsOptions = [
  { value: 'z-internetu', display: 'z Internetu' },
  { value: 'z-polecenia', display: 'z polecenia' },
  { value: 'z-reklamy', display: 'z reklamy' },
  { value: 'inne', display: 'Inne' },
]
